<template>

  <div class="page-wrapper">
    <footer>
      <div class="footer-top text-left font-muli ">
        <div class="pt-exebar">

        </div>
        <div class="container max-w-8xl">
          <div class="flex flex-nowrap -ml-1 -mr-1">
            <div class="w-1/4 flex-shrink-0 flex-grow-0 px-5">

              <h5 class="footer-title">Address</h5>

              <div class="gem-contacts-item gem-contacts-address">Corporate Office :<br> Doon House, B-275(A), First
                floor
                Sector-57, Shushant Lok 3
                Near Hong Kong Bazzar, Gurugram Pin 122001, Haryana.
              </div>
              <div class="gem-contacts-item gem-contacts-phone flex">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 mr-4 fill-current" viewBox="0 0 512 512">
                  <path
                      d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"/>
                </svg>
                Phone: <a class="mx-2"
                          href="">+91-9122588799</a></div>

              <div class="gem-contacts-item gem-contacts-address mt-2">Reg. Office :<br> Doon House, D2/3,
                4th Floor, Chandra Tower, IDBI Bank Building
                Dimna Road, Mango, Jamshedpur-831012, Jharkhand.
              </div>
              <div class="gem-contacts-item gem-contacts-phone flex">
                <svg
                     class="w-6 mr-4 fill-current"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                  <path
                      d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"/>
                </svg>
                Phone:
                <a class="mx-2">+91 9122588799</a></div>


            </div>

            <div class="widget footer_widget px-8">
                    <h5 class="footer-title">Recent News</h5>
                    <ul class="posts  styled">
                      <li class="clearfix gem-pp-posts">
                        <div class="gem-pp-posts-text">
                          <div class="gem-pp-posts-item">
                            <a href="#">Want to start Your Own Play School.
                            </a>
                          </div>
                          <div class="gem-pp-posts-date">Call +91-9122588799</div>
                        </div>
                      </li>
                      <li class="clearfix gem-pp-posts">
                        <div class="gem-pp-posts-text">
                          <div class="gem-pp-posts-item">
                            <a href="#">Now we are in Faridabad,<br>Now we are in DaudNagar.
                            </a>
                          </div>
                          <div class="gem-pp-posts-date">Call +91-9122588799, <br> +91-9122588799</div>
                        </div>
                      </li>
                      <li class="clearfix gem-pp-posts">
                        <div class="gem-pp-posts-text">
                          <div class="gem-pp-posts-item">
                            <a href="">Now we are in Ranchi,<br> Admission open
                            </a>
                          </div>
                          <div class="gem-pp-posts-date">Call +91-9122588799,<br> +91-9122588799</div>
                        </div>
                      </li>

                    </ul>
                  </div>

              <div class="mb-12 px-8">
                    <h5 class="footer-title">Email Us</h5>
                    <div class="textwidget ">
                      <div role="form" class="wpcf7 " id="wpcf7-f4-o1" lang="en-US" dir="ltr">

                        <form method="post" class="wpcf7-form font-medium" novalidate="novalidate">

                          <div class="contact-form-footer" >
                            <p><span class="wpcf7-form-control-wrap  placeholder-blue-600"><input type="text" name="your-first-name" value="" size="40" class="wpcf7-form-control wpcf7-text" aria-invalid="false" placeholder="Your name"></span></p>
                            <p><span class="wpcf7-form-control-wrap your-email_1"><input type="email" name="your-email_1" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email" aria-invalid="false" placeholder="Your email"></span></p>
                            <p><span class="wpcf7-form-control-wrap your-message"><textarea name="your-message" cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Your message"></textarea></span></p>
                            <div><input type="submit" value="Send" class="wpcf7-form-control wpcf7-submit"><span class="ajax-loader"></span></div>
                          </div>
                        </form>
                    </div>
                  </div>
                </div>


            <div class="widget widget_gallery gallery-grid-4 w-1/5 ">
              <h5 class="footer-title">Our Gallery</h5>
              <ul class="grid grid-cols-3 grid-rows-3">
                <li><a class="magnific-anchor"><img
                    src="https://images.unsplash.com/photo-1477239439998-839196943351?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=414&q=80"
                    alt=""></a></li>
                <li><a class="magnific-anchor"><img
                    src="https://images.unsplash.com/photo-1477239439998-839196943351?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=414&q=80"
                    alt=""></a></li>
                <li><a class="magnific-anchor"><img
                    src="https://images.unsplash.com/photo-1477239439998-839196943351?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=414&q=80"
                    alt=""></a></li>
                <li><a class="magnific-anchor"><img
                    src="https://images.unsplash.com/photo-1477239439998-839196943351?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=414&q=80"
                    alt=""></a></li>
                <li><a class="magnific-anchor"><img
                    src="https://images.unsplash.com/photo-1477239439998-839196943351?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=414&q=80"
                    alt=""></a></li>
                <li><a class="magnific-anchor"><img
                    src="https://images.unsplash.com/photo-1477239439998-839196943351?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=414&q=80"
                    alt=""></a></li>
                <li><a class="magnific-anchor"><img
                    src="https://images.unsplash.com/photo-1477239439998-839196943351?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=414&q=80"
                    alt=""></a></li>
                <li><a class="magnific-anchor"><img
                    src="https://images.unsplash.com/photo-1477239439998-839196943351?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=414&q=80"
                    alt=""></a></li>
                <li><a class="magnific-anchor"><img
                    src="https://images.unsplash.com/photo-1477239439998-839196943351?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=414&q=80"
                    alt=""></a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div class="footer-bottom ">
        <div class="container">
          <div class="flex justify-around">

              <div class="footer-site-info font-medium">2020 ©
                <a href="https://www.youtube.com/watch?v=pdr4IzBpacI&t=34s"
                   target="_blank">Top HTML & CSS Program </a></div>

            <div>
              <nav id="footer-navigation" class="site-navigation footer-navigation centered-box" role="navigation">
                <ul id="footer-menu" class="nav-menu styled clearfix inline-inside font-medium">
                  <li id="menu-item-26" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26"><a
                      href="#">Support</a></li>
                  <li id="menu-item-27" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-27"><a
                      href="#">Contact Us</a></li>
                  <li id="menu-item-28" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-28"><a
                      href="#">Disclaimer</a></li>
                  <li id="menu-item-29" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-29"><a
                      href="#">Add more</a></li>
                </ul>
              </nav>
            </div>

              <div id="footer-socials">
                <div class="socials inline-inside socials-colored ">

                  <a href="#" target="_blank" title="Facebook" class="socials-item font-bold">
                    <svg class="w-8 h-8 facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
                  </a>
                  <a href="#" target="_blank" title="Twitter" class="socials-item">
                    <svg class="w-8 h-8 twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>

                  </a>
                  <a href="#" target="_blank" title="Instagram" class="socials-item">
                    <svg class="w-8 h-8 instagram" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                  </a>
                  <a href="#" target="_blank" title="YouTube" class="socials-item">
                    <svg class="w-8 h-8 youtube" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg>
                  </a>
                  <a href="#" target="_blank" title="Telegram" class="socials-item">
                    <svg class="w-8 h-8 telegram" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"/></svg>
                  </a>
                </div>
              </div>


          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterNr1"
}
</script>




<style scoped>

@import url("https://use.typekit.net/zsm0hjd.css");


a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}


.page-wrapper {
  bottom: 0;
  width: 100%;
  z-index: 11111;
  top: 35px;
}

footer p, footer strong, footer b, footer {
  color: #b0b0b0;
}

.footer-top {
  background: #303030;
  background-size: cover;
  background-position: center;
  padding: 0 0 20px;

  padding-top: 30px;

}

.footer-top, .footer-bottom {
  background-color: #1c1f2f;
}

.footer-bottom {
  padding: 15px 0;
  border-top: 1px solid #313646;
  background-color: #181828 !important;
  color: #b0b0b0;

  color: #99a9b5;
  padding-top: 15px;
}


.footer-site-info {
  font-size: 92.86%;
}

#footer-navigation, #footer-navigation li a:hover, .custom-footer, .custom-footer li a:hover {
  color: white;
}

#footer-navigation, #footer-navigation li a, .custom-footer, .custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

.footer-bottom ul {
  margin: 0;
}

.inline-inside {
  font-size: 0;
  line-height: 0;
}

.clearfix:after, .clearfix:before {
  content: "";
  display: table;
}

#footer-menu li {
  display: inline-block;
  padding: 0 21px;
  position: relative;
  line-height: 1;
}

#footer-navigation, #footer-navigation li a, .custom-footer, .custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

#footer-navigation, #footer-navigation li a, .custom-footer, .custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

#footer-menu li + li:before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
  font-size: 0;
  border-left: 1px solid #232234;
  border-right: 1px solid #333146;
}


navigation li a, .custom-footer, .custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

#footer-socials {
  text-align: right;
}

#footer-socials .socials {
  text-align: right;
  margin: 0 -7px;
  display: inline-block;
  vertical-align: middle;
}

a.socials-item {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0 5px;
  line-height: 16px;
  padding: 10px;
  border-radius: 50%;
  background-color: #141421;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgb(0, 0, 0, 0.4), -3px -3px 10px rgba(255, 255, 255, 0.06), inset 14px 14px 26px rgb(0, 0, 0, 0.3), inset -3px -3px 15px rgba(255, 255, 255, 0.05);
}

.facebook {
  fill: #4e64b5!important;
}

.twitter {
  fill: #00aced;
}

.instagram {
  fill: #9a8f62;
}

.youtube {
  fill: #c82929;
}

.telegram {
  fill: #2ca5e0;
}


a.socials-item:hover {
  box-shadow: 0 0px 20px rgba(84, 1, 74, 0.7);
  border-color: rgba(255, 6, 224, 0.61);
  background: linear-gradient(to right, rgba(255, 9, 9, 0.12941176470588237), #c000ffb5, rgba(255, 0, 94, 0.14));
}

.footer-bottom a:hover {
  color: white;
}


footer p, footer li {
  font-size: 15px;
  line-height: 22px;
}


.footer-title {
  margin-bottom: 40px;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  padding-bottom: 15px;
  font-size: 16px;
  position: relative;
}

.footer-title:after {
  width: 50px;
  background: #fff;
  opacity: 0.2;
  height: 1px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
}

.gem-contacts-item {
  padding-top: 10px;
  font-size: 15px;
}

.gem-contacts-item i {
  padding-right: 10px;
}

footer .widget ul {
  list-style: none;
  margin-top: 5px;
}

.posts li {
  border-bottom: 1px solid #393d50;
  padding-bottom: 12px;
  padding-top: 6px;
}

footer p, footer li {
  font-size: 15px;
  line-height: 22px;
}

.gem-pp-posts-date {
  color: #00bcd4;
  font-size: 89.5%;
}

footer p {
  line-height: 24px;
  margin-bottom: 10px;
  font-size: 15px;
}

.wpcf7-form-control-wrap .wpcf7-form-control {
  padding: 7px !important;
  width: 100%;
}

.wpcf7-form-control-wrap input {
  background: #1c1f2f;
  overflow: hidden;
  border: 1px solid #2e344d;
  background-color: #1c1f2f;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03);
  border-radius: 5px;
  transition: all 0.3s ease-in-out 0s;
}

.wpcf7-form-control-wrap input:hover {
  background-color: transparent;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03), inset 14px 14px 70px rgb(0, 0, 0, 0.2), inset -15px -15px 30px rgba(255, 255, 255, 0.04);
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.wpcf7 .wpcf7-form .contact-form-footer textarea {
  height: 160px;
  width: 100%;
}

.wpcf7-form-control-wrap textarea:hover {
  background-color: transparent;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03), inset 14px 14px 70px rgb(0, 0, 0, 0.2), inset -15px -15px 30px rgba(255, 255, 255, 0.04);
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.wpcf7-form-control-wrap textarea {
  background: #1c1f2f;
  overflow: hidden;
  border: 1px solid #2e344d;
  background-color: #1c1f2f;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03);
  border-radius: 5px;
  transition: all 0.3s ease-in-out 0s;
}

textarea {
  overflow: auto;
  resize: vertical;
}

.wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit {
  width: 100%;
  padding: 11px;
  margin: 0;
  line-height: 0;
}

.wpcf7-form .contact-form-footer .wpcf7-submit {
  background-color: #394050;
  color: #99a9b5;
  border: none;
  cursor: pointer;
  padding: 15px 40px;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.5s;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, .5);
  box-shadow: none;
  text-transform: uppercase;
  outline: none !important;
  background-color: #1c1f2f;
  border-radius: 5px;
  min-width: 140px;
  /* box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03), inset 14px 14px 70px rgb(0,0,0,0.2), inset -15px -15px 30px rgba(255,255,255, 0.04); */
  box-shadow: 3px 9px 16px rgb(0, 0, 0, 0.4), -3px -3px 10px rgba(255, 255, 255, 0.06), inset 14px 14px 26px rgb(0, 0, 0, 0.3), inset -3px -3px 15px rgba(255, 255, 255, 0.05);
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #2e344d;
  transition: all 0.3s ease-in-out 0s;
}

.wpcf7-form input[type=submit] {
  height: 40px;
  line-height: 21px;
  padding: 10px 40px;
  font-size: 14px;
}

.posts li a {
  color: #99a9b5;
}

.wpcf7-form .contact-form-footer .wpcf7-submit:hover {
  box-shadow: 0 0px 20px rgba(84, 1, 74, 0.7);
  border-color: rgba(255, 6, 224, 0.61);
  background: linear-gradient(to right, rgba(255, 9, 9, 0.12941176470588237), #c000ffb5, rgba(255, 0, 94, 0.14));
  color: white;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.widget_gallery a {
  display: inline-block;
}

footer .widget ul {
  list-style: none;
  margin-top: 5px;
}

.widget_gallery ul {
  padding-left: 0;
  display: table;
}

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  padding: 2px;
}

.widget_gallery.gallery-grid-4 li {
  width: 30%;
}


#waterdrop {
  height: 30px;
}

#waterdrop canvas {
  bottom: -70px !important;
}

.footer-site-info {
  padding-top: 10px;
}


</style>