<template>

  <div class="main py-14"></div>
  <div class="footer">
    <div class="bubbles">
      <div class="bubble" style="--size:3.1685738211749106rem; --distance:7.6115954038371045rem; --position:20.659942579796727%; --time:3.3296461018112855s; --delay:-2.4457725664004566s;"></div>
      <div class="bubble" style="--size:5.548778742797304rem; --distance:7.887299364154778rem; --position:58.995179717824094%; --time:3.100714773648742s; --delay:-2.3895898254888075s;"></div>
      <div class="bubble" style="--size:2.3578103405611532rem; --distance:9.801472582760004rem; --position:23.877951095799318%; --time:3.8399909399274246s; --delay:-3.7421849875812745s;"></div>
      <div class="bubble" style="--size:3.4868084981352467rem; --distance:9.24100365993895rem; --position:81.95776327930133%; --time:3.961864708155694s; --delay:-3.529355990235845s;"></div>
      <div class="bubble" style="--size:4.240864142235385rem; --distance:6.608147342227146rem; --position:95.17802211980533%; --time:2.0434338545911443s; --delay:-2.50424544152486s;"></div>
      <div class="bubble" style="--size:3.522543021400283rem; --distance:9.557522182033734rem; --position:53.00947815462622%; --time:2.445745316918238s; --delay:-2.743938655795979s;"></div>
      <div class="bubble" style="--size:3.8713035917902605rem; --distance:9.286432733169375rem; --position:53.33383020802341%; --time:3.749060282919491s; --delay:-3.8034405102517614s;"></div>
      <div class="bubble" style="--size:2.999851239463929rem; --distance:6.3673123394315905rem; --position:22.574133256423192%; --time:2.798396633588125s; --delay:-2.609917694434035s;"></div>
      <div class="bubble" style="--size:2.4708400520353084rem; --distance:6.154608417002366rem; --position:57.0959744599867%; --time:2.89143417355244s; --delay:-2.815715442353516s;"></div>
      <div class="bubble" style="--size:2.3544297457931966rem; --distance:6.493025960940163rem; --position:94.16746295536606%; --time:3.9534838230325917s; --delay:-2.139175188791705s;"></div>
      <div class="bubble" style="--size:3.435305446442799rem; --distance:6.298526929860624rem; --position:2.1539578408475535%; --time:2.255603584499988s; --delay:-2.3008663864584213s;"></div>
      <div class="bubble" style="--size:4.957615724741232rem; --distance:8.721662621746333rem; --position:5.432694528484504%; --time:2.130899107699207s; --delay:-3.1523808492634395s;"></div>
      <div class="bubble" style="--size:5.744854653105254rem; --distance:6.420095158336523rem; --position:6.1329414460503155%; --time:2.6263075649067416s; --delay:-3.8422576506169546s;"></div>
      <div class="bubble" style="--size:2.150398109613982rem; --distance:9.854391613518068rem; --position:94.97138502160064%; --time:3.460522805301003s; --delay:-2.172079149974975s;"></div>
      <div class="bubble" style="--size:5.564177172141773rem; --distance:9.907238021591276rem; --position:59.78941545069242%; --time:3.399043291616251s; --delay:-2.534543366813447s;"></div>
      <div class="bubble" style="--size:2.616393953639756rem; --distance:9.058566869517893rem; --position:80.4129939826733%; --time:2.6458423955590593s; --delay:-2.5060019089490506s;"></div>
      <div class="bubble" style="--size:4.601510383315635rem; --distance:9.43983851860451rem; --position:96.47233422574564%; --time:2.61327731469205s; --delay:-3.2408388224834903s;"></div>
      <div class="bubble" style="--size:3.0822856834298547rem; --distance:8.059578583165894rem; --position:31.611000708705028%; --time:3.982313196495207s; --delay:-3.529481987753113s;"></div>
      <div class="bubble" style="--size:5.822895904654959rem; --distance:8.239918575087692rem; --position:82.41884778064856%; --time:3.3373827776200344s; --delay:-2.4781101620149286s;"></div>
      <div class="bubble" style="--size:4.293769368557844rem; --distance:9.181224056336408rem; --position:42.97104282457057%; --time:2.652590267087977s; --delay:-2.9293091994470766s;"></div>
      <div class="bubble" style="--size:2.3836451730912707rem; --distance:7.854257153013801rem; --position:40.69825195384923%; --time:2.651070472269394s; --delay:-3.347360832533595s;"></div>
      <div class="bubble" style="--size:2.603095937007059rem; --distance:9.081905990511402rem; --position:-2.286549948691956%; --time:2.7735706869618983s; --delay:-3.610974233017592s;"></div>
      <div class="bubble" style="--size:3.343172536747411rem; --distance:9.261967798743846rem; --position:18.340742240006676%; --time:2.3577839838045396s; --delay:-2.7241590685846933s;"></div>
      <div class="bubble" style="--size:3.3925028599633116rem; --distance:8.10257983812571rem; --position:25.99449696280413%; --time:2.1886360259523028s; --delay:-3.755022928702429s;"></div>
      <div class="bubble" style="--size:5.331687535592576rem; --distance:8.851140744807843rem; --position:48.34360402728777%; --time:3.479832760260207s; --delay:-2.986068665490409s;"></div>
      <div class="bubble" style="--size:5.582839083050499rem; --distance:7.780555685156781rem; --position:17.646446081765855%; --time:3.7167588761009682s; --delay:-2.2100028897830004s;"></div>
      <div class="bubble" style="--size:3.966453426558502rem; --distance:8.237435296243824rem; --position:-1.0202505423252473%; --time:2.3079399178822704s; --delay:-3.8353506967152686s;"></div>
      <div class="bubble" style="--size:5.764180774672419rem; --distance:8.18407038182573rem; --position:65.10254059196163%; --time:2.5342758712401414s; --delay:-2.3235721124084883s;"></div>
      <div class="bubble" style="--size:4.608204744560122rem; --distance:9.678298661364497rem; --position:0.6328067499940726%; --time:3.6124575511480987s; --delay:-3.9043040729546195s;"></div>
      <div class="bubble" style="--size:3.958389403206648rem; --distance:7.869998442418087rem; --position:61.99869779820936%; --time:2.9116329187036722s; --delay:-2.9052728680088165s;"></div>
      <div class="bubble" style="--size:4.529311013521718rem; --distance:8.158671066339705rem; --position:62.3558066659341%; --time:3.21671093754349s; --delay:-3.8438231754046828s;"></div>
      <div class="bubble" style="--size:3.8430137677096683rem; --distance:7.868591275816667rem; --position:50.50109463006729%; --time:3.203791242710785s; --delay:-3.9978008014467954s;"></div>
      <div class="bubble" style="--size:5.410212874200752rem; --distance:6.518816479971816rem; --position:82.7344851236746%; --time:2.048510770485237s; --delay:-2.539947642967969s;"></div>
      <div class="bubble" style="--size:2.2451368804971654rem; --distance:7.876434897501573rem; --position:1.048674112540759%; --time:2.752517581579288s; --delay:-3.8427079968246796s;"></div>
      <div class="bubble" style="--size:3.24300182612724rem; --distance:7.558383837199051rem; --position:54.112199684066134%; --time:3.4136017252381703s; --delay:-3.088549306807465s;"></div>
      <div class="bubble" style="--size:5.671731869404867rem; --distance:9.0936973771832rem; --position:50.99077635585433%; --time:3.6501084757311553s; --delay:-2.459932904369284s;"></div>
      <div class="bubble" style="--size:5.340636842224725rem; --distance:7.473872514910466rem; --position:93.3442339663764%; --time:3.553133126585013s; --delay:-2.846012903699652s;"></div>
      <div class="bubble" style="--size:2.3116263029427353rem; --distance:8.410282563371993rem; --position:64.23635094558283%; --time:3.0403949602347136s; --delay:-3.46215262932549s;"></div>
      <div class="bubble" style="--size:3.9210378024595194rem; --distance:7.975839494973974rem; --position:-0.14722385525398618%; --time:3.7274083272954024s; --delay:-2.2129454138381246s;"></div>
      <div class="bubble" style="--size:2.1596611969724924rem; --distance:9.00176516439237rem; --position:74.1930066533946%; --time:3.431636049815308s; --delay:-3.3235088941678956s;"></div>
      <div class="bubble" style="--size:5.963403172784128rem; --distance:6.544442873628788rem; --position:53.41497636221744%; --time:3.2863202109466116s; --delay:-3.430155415422463s;"></div>
      <div class="bubble" style="--size:2.184117839452906rem; --distance:6.868792316627787rem; --position:4.174829439186741%; --time:3.3872529448195934s; --delay:-3.3059546983655426s;"></div>
      <div class="bubble" style="--size:5.363260152805532rem; --distance:9.540286813877817rem; --position:12.616533351210734%; --time:2.777716318057222s; --delay:-3.8985697556921894s;"></div>
      <div class="bubble" style="--size:5.771053019567449rem; --distance:6.094867714323603rem; --position:65.32015758666881%; --time:3.648623338621927s; --delay:-3.3183762658222173s;"></div>
      <div class="bubble" style="--size:5.583581266224532rem; --distance:6.8623440166223455rem; --position:33.38554618698855%; --time:3.7004257959869116s; --delay:-3.392537879568555s;"></div>
      <div class="bubble" style="--size:3.0998850725414595rem; --distance:8.430669992771325rem; --position:31.291789899326552%; --time:3.3239575387668037s; --delay:-2.48380249825935s;"></div>
      <div class="bubble" style="--size:2.2935254691626596rem; --distance:6.259811551410084rem; --position:68.02364344354713%; --time:2.764951907779578s; --delay:-3.06112225333856s;"></div>
      <div class="bubble" style="--size:3.078530615646665rem; --distance:9.887144196916063rem; --position:50.14810162383094%; --time:2.2504194607728154s; --delay:-3.0285461283736574s;"></div>
      <div class="bubble" style="--size:2.3215540484442956rem; --distance:7.910337413556062rem; --position:33.72124823166631%; --time:2.804060286569424s; --delay:-3.56418751623024s;"></div>
      <div class="bubble" style="--size:2.5364520783283844rem; --distance:8.882845079312045rem; --position:27.254740015948812%; --time:3.114446279789549s; --delay:-2.544221043701535s;"></div>
      <div class="bubble" style="--size:4.961677772017552rem; --distance:6.263476328877857rem; --position:55.07441360901185%; --time:2.922739011293628s; --delay:-3.607548996890344s;"></div>
      <div class="bubble" style="--size:5.806531563910964rem; --distance:7.814138073945896rem; --position:67.27911001377623%; --time:2.789905720592479s; --delay:-2.6809069160759487s;"></div>
      <div class="bubble" style="--size:5.947634604950702rem; --distance:7.025962788909638rem; --position:87.52110787674115%; --time:3.338759002883367s; --delay:-2.256261196696929s;"></div>
      <div class="bubble" style="--size:5.684997261093649rem; --distance:9.878401851602053rem; --position:34.11592499373922%; --time:2.021229417560878s; --delay:-3.6628402596432474s;"></div>
      <div class="bubble" style="--size:5.026635691206009rem; --distance:8.07374981391918rem; --position:5.205292174473993%; --time:3.393418342936236s; --delay:-2.608736016267213s;"></div>
      <div class="bubble" style="--size:5.242830843158635rem; --distance:9.887832393316621rem; --position:54.36087625305157%; --time:3.9015239491746714s; --delay:-2.975087171000594s;"></div>
      <div class="bubble" style="--size:4.9712282149291305rem; --distance:7.476229959384853rem; --position:25.076704167036162%; --time:3.1803194401475525s; --delay:-2.0140151559636s;"></div>
      <div class="bubble" style="--size:4.320618633841315rem; --distance:9.514364461684913rem; --position:61.869867217669096%; --time:3.584304259852726s; --delay:-2.140791478861482s;"></div>
      <div class="bubble" style="--size:2.9196981826332333rem; --distance:8.028198080288645rem; --position:6.050155149569687%; --time:3.399075786983793s; --delay:-3.866292540221947s;"></div>
      <div class="bubble" style="--size:2.1923782718420846rem; --distance:8.120780123614676rem; --position:39.81536362769106%; --time:3.2710839037477824s; --delay:-3.3440409665604527s;"></div>
      <div class="bubble" style="--size:3.2869455160562095rem; --distance:8.556335623955645rem; --position:62.96932197642822%; --time:3.064099719288037s; --delay:-2.848524684577433s;"></div>
      <div class="bubble" style="--size:4.730741162639653rem; --distance:7.113027821089259rem; --position:30.40661103830658%; --time:2.854555241994123s; --delay:-3.84983249359308s;"></div>
      <div class="bubble" style="--size:4.674337943181112rem; --distance:6.587733928946162rem; --position:25.833353586522453%; --time:2.0559178343573397s; --delay:-2.0188062810402365s;"></div>
      <div class="bubble" style="--size:5.871662591651245rem; --distance:9.271048614710075rem; --position:92.222288199981%; --time:3.4958771582583865s; --delay:-2.565884133180248s;"></div>
      <div class="bubble" style="--size:2.9286808007889586rem; --distance:7.690884171447976rem; --position:89.29315092902239%; --time:2.9251317392908502s; --delay:-3.392924099533306s;"></div>
      <div class="bubble" style="--size:5.754036047289557rem; --distance:9.09568620489831rem; --position:68.82132502843616%; --time:3.8007263053909224s; --delay:-3.5045462969266596s;"></div>
      <div class="bubble" style="--size:2.006302905368017rem; --distance:8.729761329069957rem; --position:78.3487460335706%; --time:3.9742433018762524s; --delay:-2.578957668822078s;"></div>
      <div class="bubble" style="--size:3.6712078384153752rem; --distance:8.182455858703701rem; --position:84.44733396147896%; --time:3.751344378726169s; --delay:-2.8484657673308726s;"></div>
      <div class="bubble" style="--size:5.81657956718196rem; --distance:8.858554926079144rem; --position:15.650733626520392%; --time:3.8465691605884884s; --delay:-3.009850827109639s;"></div>
      <div class="bubble" style="--size:3.660278810862075rem; --distance:8.960066002684627rem; --position:96.5287286708695%; --time:2.3638845679040017s; --delay:-2.9972032881306427s;"></div>
      <div class="bubble" style="--size:3.479787378596515rem; --distance:6.92832141085102rem; --position:102.03305570627971%; --time:2.435960760093307s; --delay:-2.6952256170646733s;"></div>
      <div class="bubble" style="--size:2.7327781949809884rem; --distance:9.617158206931421rem; --position:66.06474242501511%; --time:3.7914516099953284s; --delay:-2.9135626129394754s;"></div>
      <div class="bubble" style="--size:5.229382664011705rem; --distance:6.641437328047465rem; --position:19.722856934283797%; --time:3.245839968278315s; --delay:-3.7061990467852155s;"></div>
      <div class="bubble" style="--size:2.4055692495890035rem; --distance:6.728897059730921rem; --position:35.70885724055733%; --time:2.356580821564189s; --delay:-3.422925894365994s;"></div>
      <div class="bubble" style="--size:2.486479052605654rem; --distance:7.8897766209146205rem; --position:103.44652138955215%; --time:2.1373564200650006s; --delay:-2.6256234030098766s;"></div>
      <div class="bubble" style="--size:3.239563969587283rem; --distance:6.341268082310783rem; --position:12.22460260790045%; --time:2.9932559159435725s; --delay:-3.260509482051646s;"></div>
      <div class="bubble" style="--size:5.249121221791266rem; --distance:8.671596415941995rem; --position:14.667635117236358%; --time:3.6256607390644735s; --delay:-2.502228675036372s;"></div>
      <div class="bubble" style="--size:4.748162865151686rem; --distance:8.195547765435897rem; --position:69.43684949561714%; --time:3.091671386748977s; --delay:-3.158618616676709s;"></div>
      <div class="bubble" style="--size:5.3071893683994755rem; --distance:7.782084565441872rem; --position:80.75806664334077%; --time:2.006887128086464s; --delay:-3.808051854469914s;"></div>
      <div class="bubble" style="--size:3.0389540902240695rem; --distance:7.836351306341902rem; --position:38.4256381541674%; --time:3.806043140337589s; --delay:-2.3569575991017357s;"></div>
      <div class="bubble" style="--size:5.19242192580998rem; --distance:6.996344026440174rem; --position:15.886824585750027%; --time:3.5317544446421043s; --delay:-3.5142999192451616s;"></div>
      <div class="bubble" style="--size:2.485944797966371rem; --distance:8.069825818218462rem; --position:4.482256266478%; --time:2.971274120950074s; --delay:-2.7717812140849944s;"></div>
      <div class="bubble" style="--size:4.692524289314462rem; --distance:8.26826161088498rem; --position:35.82307127859796%; --time:3.1099696757503064s; --delay:-3.204473565559972s;"></div>
      <div class="bubble" style="--size:2.7299514907117652rem; --distance:6.855313543511148rem; --position:19.33875370535172%; --time:3.971705424108956s; --delay:-2.248141969580477s;"></div>
      <div class="bubble" style="--size:4.3477146437166825rem; --distance:8.920405483602789rem; --position:83.04650502008649%; --time:2.7314336816680824s; --delay:-3.38694864733449s;"></div>
      <div class="bubble" style="--size:4.960020693780121rem; --distance:6.409542126133725rem; --position:24.852291013045825%; --time:3.5852579079986446s; --delay:-3.526649546786978s;"></div>
      <div class="bubble" style="--size:4.474143787932019rem; --distance:9.784002153407707rem; --position:81.1700154421798%; --time:3.045116364380448s; --delay:-2.997923183222338s;"></div>
      <div class="bubble" style="--size:5.668181927399419rem; --distance:6.891936146521334rem; --position:44.17482369663406%; --time:2.7883010353076245s; --delay:-2.310111568454149s;"></div>
      <div class="bubble" style="--size:3.3147221048346207rem; --distance:8.451244800270509rem; --position:24.646884233225222%; --time:3.7911196326500463s; --delay:-2.642698276787149s;"></div>
      <div class="bubble" style="--size:2.4199221988737154rem; --distance:9.4493201158649rem; --position:14.008359980076612%; --time:2.8032380549030567s; --delay:-3.9728278428444663s;"></div>
      <div class="bubble" style="--size:5.14162412894842rem; --distance:8.450591879457658rem; --position:100.97987058223359%; --time:3.834098458934336s; --delay:-2.120911854426032s;"></div>
      <div class="bubble" style="--size:4.185071197119701rem; --distance:6.215114849430779rem; --position:80.32572838555274%; --time:2.7239128574683082s; --delay:-2.498194610693473s;"></div>
      <div class="bubble" style="--size:2.990381412321863rem; --distance:9.831942062069807rem; --position:91.25766017373938%; --time:3.7835585935452936s; --delay:-3.7753767738494783s;"></div>
      <div class="bubble" style="--size:5.8864741321872405rem; --distance:8.324988155224696rem; --position:2.599343547216506%; --time:3.167077556258298s; --delay:-3.4840536650462313s;"></div>
      <div class="bubble" style="--size:4.538292189861782rem; --distance:6.649818997951709rem; --position:11.241732219988613%; --time:3.868347618249129s; --delay:-2.1958987302853705s;"></div>
      <div class="bubble" style="--size:5.984939592396057rem; --distance:9.061788152774646rem; --position:47.24958656034776%; --time:3.919731452903128s; --delay:-2.5020954128284476s;"></div>
      <div class="bubble" style="--size:4.233076682485591rem; --distance:7.869871484156133rem; --position:65.13542161868354%; --time:2.1843459038441058s; --delay:-2.3782829090851636s;"></div>
      <div class="bubble" style="--size:5.202336272226337rem; --distance:9.660615486787506rem; --position:73.1038753504204%; --time:2.3600183143601083s; --delay:-2.611053189173541s;"></div>
      <div class="bubble" style="--size:2.2182099480778543rem; --distance:8.763137793334108rem; --position:99.69978615618629%; --time:3.082844445392786s; --delay:-2.535626230527751s;"></div>
      <div class="bubble" style="--size:5.66852957915821rem; --distance:9.675627837856087rem; --position:16.438285190243256%; --time:2.160967303683834s; --delay:-2.6705940818939897s;"></div>
      <div class="bubble" style="--size:5.243164805438676rem; --distance:9.545107112537156rem; --position:82.2929786407993%; --time:2.3764200451915634s; --delay:-2.0365335937977638s;"></div>
      <div class="bubble" style="--size:3.1813786012937566rem; --distance:6.519204344688385rem; --position:32.753743698857036%; --time:3.747894683261202s; --delay:-2.8464865425997186s;"></div>
      <div class="bubble" style="--size:4.398416598540994rem; --distance:6.001269797232133rem; --position:39.92731022127572%; --time:2.9963579392735715s; --delay:-3.68738621564344s;"></div>
      <div class="bubble" style="--size:5.9722706494874rem; --distance:7.3582365497179305rem; --position:58.95327279395498%; --time:2.593615183376168s; --delay:-2.923211970388475s;"></div>
      <div class="bubble" style="--size:4.471361465712503rem; --distance:6.428569293441791rem; --position:80.24010048575573%; --time:2.752117094846151s; --delay:-3.9690514450646255s;"></div>
      <div class="bubble" style="--size:5.955675306535143rem; --distance:7.811047290976439rem; --position:100.8798816020584%; --time:3.8579412478146966s; --delay:-3.316486287144821s;"></div>
      <div class="bubble" style="--size:5.004182109778305rem; --distance:8.056569537795323rem; --position:31.715885369364216%; --time:2.784424709636624s; --delay:-2.5829631048409163s;"></div>
      <div class="bubble" style="--size:5.161398359827251rem; --distance:8.347793144237109rem; --position:21.08727354034566%; --time:2.513881902395387s; --delay:-3.9966198232784893s;"></div>
      <div class="bubble" style="--size:2.1142419448023784rem; --distance:6.238038047937151rem; --position:19.092832897772514%; --time:2.5164292950223763s; --delay:-3.538746058001676s;"></div>
      <div class="bubble" style="--size:5.375609574652242rem; --distance:7.360648773439979rem; --position:13.10621988808099%; --time:2.0196362731105024s; --delay:-3.5359074885223296s;"></div>
      <div class="bubble" style="--size:4.7262236632085655rem; --distance:9.490136629497172rem; --position:22.010664778620455%; --time:3.1336856937231894s; --delay:-2.510947449736768s;"></div>
      <div class="bubble" style="--size:4.192514114375958rem; --distance:6.690194549219885rem; --position:26.167789709891633%; --time:2.727414423362662s; --delay:-3.5363730948485688s;"></div>
      <div class="bubble" style="--size:3.0569037685069853rem; --distance:7.981155860765808rem; --position:88.50177149548233%; --time:2.4391991192463904s; --delay:-2.072370030851999s;"></div>
      <div class="bubble" style="--size:5.752101781477501rem; --distance:8.06221841760345rem; --position:50.630040678634515%; --time:3.1925653462886494s; --delay:-2.7941740718300263s;"></div>
      <div class="bubble" style="--size:5.9863319436766265rem; --distance:8.49166823539683rem; --position:59.47247932660254%; --time:2.8707149979288102s; --delay:-3.1278549219928395s;"></div>
      <div class="bubble" style="--size:2.8823369878105076rem; --distance:9.661883001169592rem; --position:77.88793659949661%; --time:3.3840247649815036s; --delay:-3.3084661576265324s;"></div>
      <div class="bubble" style="--size:2.7868977263636854rem; --distance:6.7908952976073405rem; --position:37.68704805357844%; --time:3.9558159519152714s; --delay:-3.570145683344031s;"></div>
      <div class="bubble" style="--size:5.290947854958157rem; --distance:9.415420414780609rem; --position:102.68585255569215%; --time:3.1506368399258693s; --delay:-3.4313306499997216s;"></div>
      <div class="bubble" style="--size:3.2994577723403644rem; --distance:8.725608487494693rem; --position:0.9554878891124492%; --time:2.4657050596437893s; --delay:-3.858679737580108s;"></div>
      <div class="bubble" style="--size:3.455185196046971rem; --distance:9.876157896319178rem; --position:77.67213982851679%; --time:2.1138110672080503s; --delay:-3.868903430002541s;"></div>
      <div class="bubble" style="--size:5.361791712337852rem; --distance:8.97456575604858rem; --position:59.33737619043572%; --time:3.788212730550906s; --delay:-2.1040784083794226s;"></div>
      <div class="bubble" style="--size:4.629140499906192rem; --distance:6.384333486735148rem; --position:96.68607351093803%; --time:3.6479192141601353s; --delay:-2.8623052389945265s;"></div>
      <div class="bubble" style="--size:5.518308813950342rem; --distance:8.779886839227913rem; --position:10.186943968913374%; --time:2.6988670339542344s; --delay:-3.7058464591007576s;"></div>
      <div class="bubble" style="--size:3.745830870965756rem; --distance:9.788440778448589rem; --position:71.60030966151042%; --time:3.8496069131034782s; --delay:-3.32830237317503s;"></div>
      <div class="bubble" style="--size:5.463340571363714rem; --distance:8.176822656675675rem; --position:75.9419883289032%; --time:2.1289923559380353s; --delay:-3.3522219674518388s;"></div>
      <div class="bubble" style="--size:4.989812379339317rem; --distance:8.685058462633506rem; --position:36.78732521778182%; --time:2.026884505435324s; --delay:-2.2548196936733813s;"></div>
      <div class="bubble" style="--size:3.6203953108743683rem; --distance:6.904189346296319rem; --position:45.26454905138536%; --time:3.7207230491477645s; --delay:-3.7505555829573543s;"></div>
      <div class="bubble" style="--size:4.930532708526376rem; --distance:8.402695540993705rem; --position:33.835571006804074%; --time:3.437781210687067s; --delay:-2.3894729201674454s;"></div>
    </div>
    <div class="content text-left">
      <div>
        <div><b>Eldew</b><a href="#">Secuce</a><a href="#">Drupand</a><a href="#">Oceash</a><a href="#">Ugefe</a><a href="#">Babed</a></div>
        <div><b>Spotha</b><a href="#">Miskasa</a><a href="#">Agithe</a><a href="#">Scesha</a><a href="#">Lulle</a></div>
        <div><b>Chashakib</b><a href="#">Chogauw</a><a href="#">Phachuled</a><a href="#">Tiebeft</a><a href="#">Ocid</a><a href="#">Izom</a><a href="#">Ort</a></div>
        <div><b>Athod</b><a href="#">Pamuz</a><a href="#">Vapert</a><a href="#">Neesk</a><a href="#">Omemanen</a></div>
      </div>
      <div><a class="image" href="https://codepen.io/z-" target="_blank" style="background-image: url(&quot;https://s3-us-west-2.amazonaws.com/s.cdpn.io/199011/happy.svg&quot;)"></a>
        <p>©2019 Not Really</p>
      </div>
    </div>
  </div>
  <svg style="position:fixed; top:100vh">
    <defs>
      <filter id="blob">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="blob"></feColorMatrix>
        <feComposite in="SourceGraphic" in2="blob" operator="atop"></feComposite>
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "FooterNr2"
}
</script>

<style scoped lang="scss">
body {
  display:grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:"main" "footer";
  overflow-x:hidden;
  background:#F5F7FA;
  min-height:100vh;
  font-family: 'Open Sans', sans-serif;
.footer {
  z-index: 1;
  --footer-background:#ED5565;
  display:grid;
  position: relative;
  grid-area: footer;
  min-height:12rem;
.bubbles {
  position: absolute;
  top:0;
  left:0;
  right:0;
  height:1rem;
  background:var(--footer-background);
  filter:url("#blob");
.bubble {
  position: absolute;
  left:var(--position, 50%);
  background:var(--footer-background);
  border-radius:100%;
  animation:bubble-size var(--time, 4s) ease-in infinite var(--delay, 0s),
  bubble-move var(--time, 4s) ease-in infinite var(--delay, 0s);
  transform:translate(-50%, 100%);
}
}
.content {
  z-index: 2;
  display:grid;
  grid-template-columns: 1fr auto;
  grid-gap: 4rem;
  padding:2rem;
  background:var(--footer-background);
a, p {
  color:#F5F7FA;
  text-decoration:none;
}
b {
  color:white;
}
p {
  margin:0;
  font-size:.75rem;
}
>div {
  display:flex;
  flex-direction:column;
  justify-content: center;
>div {
  margin:0.25rem 0;
>* {
  margin-right:.5rem;
}
}
.image {
  align-self: center;
  width:4rem;
  height:4rem;
  margin:0.25rem 0;
  background-size: cover;
  background-position: center;
}
}
}
}
}

@keyframes bubble-size {
  0%, 75% {
    width:var(--size, 4rem);
    height:var(--size, 4rem);
  }
  100% {
    width:0rem;
    height:0rem;
  }
}
@keyframes bubble-move {
  0% {
    bottom:-4rem;
  }
  100% {
    bottom:var(--distance, 10rem);
  }
}

</style>