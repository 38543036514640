<template>

  <section>
  <div class="section5">
    <section>
      <h1 class="title inline-photo2 show-on-scroll2">Subscribe to our newsletter</h1>
      <p class="inline-photo show-on-scroll">Get latest news on property postings, bankrupt details, new installment scheme.</p>
    </section>
    <section>
      <button class="btn2">Subscribe</button>
    </section>
  </div>


  <footer>
    <div class="footer">
      <section>
        <h3>XRIGADE</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
      </section>
      <section>
        <h3>LINKS</h3>
        <a href="#"> <svg class="w-6 h-6 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"/></svg> Home</a>
        <a href="#"><svg class="w-6 h-6 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"/></svg>About Us</a>
        <a href="#"><svg class="w-6 h-6 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"/></svg>Blog</a>
        <a href="#"><svg class="w-6 h-6 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"/></svg>Contact Us</a>
      </section>
      <section>
        <h3>OTHER</h3>
        <a href="#"><svg class="w-6 h-6 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"/></svg>Privacy Policy</a>
        <a href="#"><svg class="w-6 h-6 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"/></svg>Terms & Conditions</a>
        <a href="#"><svg class="w-6 h-6 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"/></svg>Cookie Policy</a>
      </section>
      <section>
        <h3>ANY QUESTIONS?</h3>
        <a href="emailto:support@websitename.com"><span><svg  class="w-6 h-6"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 352c-16.53 0-33.06-5.422-47.16-16.41L0 173.2V400C0 426.5 21.49 448 48 448h416c26.51 0 48-21.49 48-48V173.2l-208.8 162.5C289.1 346.6 272.5 352 256 352zM16.29 145.3l212.2 165.1c16.19 12.6 38.87 12.6 55.06 0l212.2-165.1C505.1 137.3 512 125 512 112C512 85.49 490.5 64 464 64h-416C21.49 64 0 85.49 0 112C0 125 6.01 137.3 16.29 145.3z"/></svg></span><span>support@websitename.com</span></a>
        <a href="tel:xxxxxxxxx"><span><svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"/></svg></span><span>(+x)-xxx-xxxx-xxxxx</span></a>
      </section>
    </div>
  </footer>
  <div class="sub-footer">
    Copyright ©2021 All rights reserved | website name
  </div>
  </section>
</template>

<script>
export default {
  name: "FooterNr3"
}
</script>

<style scoped>
section{
  scrollbar-color: rgb(210,210,210) rgb(46,54,69) !important;
  scrollbar-width: thin !important;
  --white:#fff;
  --black:#131417;
  --primary:#5bc348;
  --primarylite:#53b141;
  --gray:#6d7175;
  --dark:#253248;
  --darklite:#364256;
  --lite:#f8f9fa;
}
::-webkit-scrollbar {
  height: 12px;
  width: 8px;
  background: var(--dark);
}
::-webkit-scrollbar-thumb {
  background: gray;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}
::-webkit-scrollbar-corner {
  background: var(--dark);
}











/*DEFAULT*/
body{
  font-family: 'Source Sans Pro', sans-serif;
  margin:0;
  overflow-x:hidden !important;
}

.title{
  font-family: 'Merriweather', serif;
}

a{
  text-decoration:none;
  transition:0.5s;
  outline:none !important;
}

li{
  list-style:none;
}

input, textarea, select, button{
  outline:none !important;
}

fieldset{
  border:0;
}

p{
  letter-spacing:0.08em;
  line-height:1.8em;
  color:var(--gray);
}

img{
  min-width:100%;
}

em{
  font-style:normal;
  color:var(--dark);
}

.btn1, .btn2{
  padding:15px 20px;
  border-radius:40px;
  text-align:center;
  display:inline-block;
  border:0;
}

.btn1{
  background-color:var(--primary);
  color:var(--white);
}

.btn1:hover{
  background-color:var(--primarylite);
}

.btn2{
  background-color:var(--dark);
  color:var(--white);
}

.btn2:hover{
  background-color:var(--darklite);
}

@media (max-width:820px){
  .btn1, .btn2{
    padding:10px 15px;
  }
}




/*SECTION5*/
.section5{
  background-color:var(--primary);
  display:flex;
  align-items:center;
}

.section5 section{
  width:100%;
  padding:2rem 4rem;
}

.section5 section p{
  color:rgba(1,1,1,0.8);
}

.section5 section .btn2{
  float:right;
}

@Media (max-width:1020px){
  .section5{
    display:block;
  }
  .section5 section{
    padding:1rem 1rem;
    text-align:center;
  }
  .section5 section .btn2{
    float:none;
  }
}








/*FOOTER*/
.footer{
  background-color:var(--dark);
  width:100%;
  display:flex;
  align-items:top;
  padding:5rem 0;
}

.footer section{
  padding:1rem 2rem;
  width:100%;
  vertical-align:top;
}

.footer section h3{
  color:var(--white);
}

.footer section svg{

  fill: white;

}
.footer section a{
  color:var(--gray);
  display:flex;
  align-items:center;
  white-space:nowrap;
  margin:15px 0;
}

.footer section a:hover{
  color:var(--white);
}

.footer section:nth-child(2) a:before,.footer section:nth-child(3) a:before{
  margin-right:5px;
  color:var(--white);
}

.footer section a span{
  margin:0 10px;
}

.sub-footer{
  background-color:#142136;
  text-align:Center;
  color:var(--gray);
  padding:1rem 0;
}

@media (max-width:1020px){
  .footer{
    display:block;
  }
  .footer section{
    padding:1rem 1rem;
  }
  .footer section:nth-child(2), .footer section:nth-child(3){
    width:49%;
    display:inline-block;
  }
}

</style>