<template>
  <h1 class="text-center text-4xl py-4">Hamburgers</h1>
  <ul class="text-center">
    <li class="pt-20 text-3xl justify-center">
      <h1 class="text-3xl py-4">Nr.1</h1>

      <FooterNr1/>
    </li>
    <li class="mt-10 text-3xl">
      <h1 class="text-3xl py-4">Nr.2</h1>
      <FooterNr2/>
    </li>
    <li class="mt-10 text-3xl">
      <h1 class="text-3xl py-4">Nr.3</h1>
      <FooterNr3/>
    </li>
    <li class="mt-10 text-3xl">
      <h1 class="text-3xl py-4">Nr.4</h1>
      <FooterNr4/>
    </li>
    <li class="mt-10 text-3xl">
      <h1 class="text-3xl py-4">Nr.5</h1>
      <FooterNr5/>
    </li>

  </ul>



</template>

<script>

import FooterNr1 from "../../components/pagesections/Footers/FooterNr1";
import FooterNr2 from "../../components/pagesections/Footers/FooterNr2";
import FooterNr3 from "../../components/pagesections/Footers/FooterNr3";
import FooterNr4 from "../../components/pagesections/Footers/FooterNr4";
import FooterNr5 from "../../components/pagesections/Footers/FooterNr5";
export default {
  name: "Footers",

  components: {FooterNr1,FooterNr2,FooterNr3,FooterNr4,FooterNr5}

}
</script>

<style scoped>

</style>